@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Satoshi", sans-serif;
}

@import "../node_modules/video-react/dist/video-react.css";
@import "../node_modules/aos/dist/aos.css";

.video-react-video {
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;
}

.video-react .video-react-poster {
  background-color: #0f0f11;
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;
  object-fit: cover;
}

.video-react .video-react-big-play-button {
  height: 4rem;
  width: 4rem;
  background-color: transparent;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

.video-react .video-react-big-play-button:before {
  top: 9px;
}


.slick-prev, .slick-next{
  display: none !important;
}

.slick-slide{
  width: unset !important;
  padding: 10px;
}